import Phaser from "phaser";

export class Card extends Phaser.Physics.Arcade.Image {
  private hasTint = false;
  private cardName!: string;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    frame: string
  ) {
    super(scene, x, y, texture, frame);
    this.cardName = frame;
    scene.physics.add.existing(this);
    this.initialize();
  }

  private initialize() {
    this.setInteractive();
    this.on("pointerdown", this.onClickHandler);
    this.scene.events.on("selectCard", this.selectCardHandler, this);
    this.scene.events.on("putCard", this.putCardHandler, this);

    // this.image.setVelocity(50, 100);
    // this.image.setBounce(1, 1);
    // this.image.setCollideWorldBounds(true);
  }

  private onClickHandler() {
    this.scene.events.emit("selectCard", this.cardName);
  }

  private selectCardHandler(cardName: string) {
    if (this.hasTint && cardName === this.cardName) {
      this.scene.events.emit("putCard", this.cardName);
    }
    if (this.hasTint) {
      this.hasTint = false;
      this.clearTint();
    } else {
      if (cardName === this.cardName) {
        console.log("Select card: ", cardName);
        this.setTint(0xb276e8);
        this.hasTint = true;
      }
    }
  }

  private putCardHandler(cardName: string) {
    if (cardName === this.cardName) {
      this.setPosition(
        450 + Phaser.Math.Between(-10, 10),
        240 + Phaser.Math.Between(-10, 10)
      );
      this.setDepth((this.scene as any).getStackSize() + 1);
    }
  }

  // public setVisible(value: boolean) {
  //   this.image.setVisible(value);
  // }

  // public setScale(value: number) {
  //   this.image.setScale(value);
  // }

  // public setTint(value: number) {
  //   console.log("this.image.hasTint", this.image.hasTint);
  //   this.image.setTint(value);
  //   this.image.hasTint = true;
  // }
}
