import Phaser from "phaser";

import { BackgroundScene, BootScene, GameScene } from "./scenes";

const config: Phaser.Types.Core.GameConfig = {
  type: Phaser.AUTO,
  backgroundColor: "#000000",
  scale: {
    mode: Phaser.Scale.RESIZE,
    autoCenter: Phaser.Scale.CENTER_BOTH,
    width: 960,
    height: 540,
    min: {
      width: 480,
      height: 270,
    },
    max: {
      width: 1920,
      height: 1080,
    },
  },
  scene: [BootScene, BackgroundScene, GameScene],
  physics: {
    default: "arcade",
    arcade: {
      // gravity: { y: 300 },
      debug: false,
    },
  },
};

export default new Phaser.Game(config);
