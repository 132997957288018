import Phaser from "phaser";

import { Card, Opponent } from "../classes";

export class GameScene extends Phaser.Scene {
  GAME_WIDTH = 960;
  GAME_HEIGHT = 540;

  private backgroundScene;
  private parent;
  private sizer;

  private stack = [] as string[];

  constructor() {
    super("GameScene");
  }

  private create() {
    const width = this.scale.gameSize.width;
    const height = this.scale.gameSize.height;

    this.parent = new Phaser.Structs.Size(width, height);
    this.sizer = new Phaser.Structs.Size(
      this.GAME_WIDTH,
      this.GAME_HEIGHT,
      Phaser.Structs.Size.FIT,
      this.parent
    );
    this.add.image(0, 0, "frame").setOrigin(0, 0).setDepth(1);

    this.parent.setSize(width, height);
    this.sizer.setSize(width, height);

    for (let i = 0; i < 7; i++) {
      const card = new Card(
        this,
        120 + 120 * i,
        450,
        "stars-fhd",
        `star-${Phaser.Math.Between(1, 103)}.png`
      );
      card.setScale(0.25);
      this.add.existing(card);
    }

    // const r2 = this.add.circle(160, 80, 50, 0x9966ff);
    new Opponent(this, 160, 80, 50);

    // r2.setStrokeStyle(4, 0xefc53f);

    this.backgroundScene = this.scene.get("BackgroundScene");
    this.backgroundScene.hideLoading();

    this.updateCamera();

    this.checkOriention(this.scale.orientation);

    this.scale.on("resize", this.resize, this);
    this.scale.on("orientationchange", this.checkOriention, this);
    this.events.on("putCard", this.putCardHandler, this);
  }

  private resize(gameSize) {
    const width = gameSize.width;
    const height = gameSize.height;

    this.parent.setSize(width, height);
    this.sizer.setSize(width, height);

    this.updateCamera();
  }

  private checkOriention(orientation: Phaser.Scale.Orientation) {
    if (orientation === Phaser.Scale.Orientation.PORTRAIT) {
      this.backgroundScene.showRotate();
      this.scene.sleep("GameScene");
    } else if (orientation === Phaser.Scale.Orientation.LANDSCAPE) {
      this.backgroundScene.hideRotate();
      this.scene.wake("GameScene");
    }
  }

  private putCardHandler(cardName: string) {
    this.stack.push(cardName);
  }

  private updateCamera() {
    const camera = this.cameras.main;

    const x = Math.ceil((this.parent.width - this.sizer.width) * 0.5);
    const y = 0;
    const scaleX = this.sizer.width / this.GAME_WIDTH;
    const scaleY = this.sizer.height / this.GAME_HEIGHT;

    camera.setViewport(x, y, this.sizer.width, this.sizer.height);
    camera.setZoom(Math.max(scaleX, scaleY));
    camera.centerOn(this.GAME_WIDTH / 2, this.GAME_HEIGHT / 2);

    this.backgroundScene.updateCamera();
  }

  public getZoom() {
    return this.cameras.main.zoom;
  }

  public getStackSize() {
    return this.stack.length;
  }
}
