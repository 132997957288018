import Phaser from "phaser";

export class Opponent {
  x: number;
  y: number;
  circle: Phaser.GameObjects.Graphics;

  constructor(scene: Phaser.Scene, x: number, y: number, radius: number) {
    this.x = x;
    this.y = y;
    this.circle = new Phaser.GameObjects.Graphics(scene);
    this.draw(radius);

    scene.add.existing(this.circle);
  }

  private draw(radius: number) {
    this.circle.clear();
    this.circle.fillStyle(0x9966ff);
    const circle = new Phaser.Geom.Circle(this.x, this.y, radius);
    this.circle.fillCircleShape(circle);
  }
}
