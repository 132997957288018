export class BootScene extends Phaser.Scene {
  loadingImg;
  loadingText;

  constructor() {
    super("BootScene");
  }

  preload() {
    this.load.image("background", "assets/backgrounds/space.jpg");
    this.load.image("loading", "assets/misc/loading.png");
    this.load.image("rotate", "assets/misc/rotate-screen.png");
    this.load.image("frame", "assets/misc/frame-960-540.png");
  }

  create() {
    this.scene.start("BackgroundScene");
  }

  public hideLoading() {
    this.loadingImg.setVisible(false);
    this.loadingText.setVisible(false);
  }
}
